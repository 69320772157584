import { PropsWithChildren } from "react";
import { Link, To } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { PsychNowLogo } from "~/common/assets";
import CrossIcon from "~/common/components/icons/CrossIcon";
import SideBarItem from "~/common/components/ui/sideBar/SideBarItem";
import { Item } from "~/common/utils/types/sideBarTypes";

interface SidebarContentProps extends PropsWithChildren {
  menuItems: Item[];
  bottomMenuItems: Item[];
  handleItemChange: (item: Item) => void;
  onClose: () => void;
  homePath: To;
}

export const SideBarContent = ({
  menuItems,
  bottomMenuItems,
  handleItemChange,
  onClose,
  homePath,
  children,
}: SidebarContentProps) => (
  <div className="fixed inset-0 z-30 flex w-64 flex-col border-r border-primary-200 bg-primary-50 py-5">
    <div
      className={twMerge(
        "flex h-full flex-col justify-between",
        children && "pb-4",
      )}
    >
      <div className="flex flex-col gap-10">
        <div className="flex h-14 items-start justify-between gap-4 px-5">
          <div className="flex h-full w-5/6 items-end">
            <Link to={homePath} onClick={onClose}>
              <img src={PsychNowLogo} alt="PsychNow Logo" />
            </Link>
          </div>
          <button onClick={onClose} className="lg:hidden">
            <CrossIcon className="size-5" />
          </button>
        </div>
        <div className="flex-1 gap-0.5">
          {menuItems.map(
            (item) =>
              !item.disabled && (
                <SideBarItem
                  onClick={() => handleItemChange(item)}
                  key={item.name}
                  selected={item.current}
                >
                  <item.Icon className="size-6 stroke-2" />
                  {item.name}
                </SideBarItem>
              ),
          )}
        </div>
      </div>
      <div className="flex flex-col">
        {bottomMenuItems.map(
          (item) =>
            !item.disabled && (
              <SideBarItem
                onClick={() => handleItemChange(item)}
                key={item.name}
                selected={item.current}
              >
                <item.Icon className="size-6 stroke-2" />
                {item.name}
              </SideBarItem>
            ),
        )}
      </div>
    </div>
    {children}
  </div>
);
