import { Navigate, useNavigate, useParams } from "react-router-dom";

import { ImagePromptCard, WaveBackgroundLayout } from "~/common/components";
import SuccessIcon from "~/common/components/layouts/SuccessIcon";
import { PATIENT_STATUS } from "~/common/constants";
import { CONSULTATION_TYPES } from "~/common/utils";
import { useGetCurrentPatient } from "~/patients/hooks";
import { ROUTES } from "~/patients/router";

export const ConsultationPartCompleted = () => {
  const navigate = useNavigate();
  const { data: user } = useGetCurrentPatient();
  const { consultationType } = useParams();
  const providerPictureSrc = user?.provider?.profile_picture_path;
  const providerName = user?.provider?.preferred_reference;

  if (user?.status === PATIENT_STATUS.ongoing_care)
    return <Navigate to={ROUTES.notFound} />;

  const consultationTypeMessage: Record<string, string> = {
    [CONSULTATION_TYPES.screening]: "Consultation request complete!",
    [CONSULTATION_TYPES.intake]: "Consultation part 1 completed!",
  };

  const title = `${consultationTypeMessage[consultationType!]}`;
  const subtitle = `${providerName} is reviewing your request and will be in touch with you very soon.`;

  return (
    <WaveBackgroundLayout className="relative justify-center p-6">
      <ImagePromptCard
        title={title}
        subtitle={subtitle}
        handleContinue={() => navigate(ROUTES.leadPatientHome)}
        imageContainerClassName="bg-green-100"
        continueLabel="Back to Home"
      >
        {providerPictureSrc ? (
          <img
            className="size-28 rounded-full object-cover"
            src={providerPictureSrc}
            alt="ProfilePicture"
          />
        ) : (
          <SuccessIcon className="w-full" />
        )}
      </ImagePromptCard>
    </WaveBackgroundLayout>
  );
};
