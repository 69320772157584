import type { Location } from "react-router-dom";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { HomeLayout, Layout, MedicationLayout } from "~/patients/layout";
import {
  ConsultationPartCompleted,
  ForgotPassword,
  InternalErrorScreen,
  LeadPatientHomeScreen,
  Login,
  NotFoundScreen,
  PatientCheckIn,
  ResetPassword,
  Settings,
  SignUpActivePatient,
  SignUpNewPatient,
  VerifiedEmail,
  VerifyEmail,
} from "~/patients/screens";
import {
  CheckListScreenScreening,
  Intake,
  Screening,
  ScreeningAlreadyCompleted,
  ScreeningIntroScreen,
  StartScreening,
} from "~/patients/screens/consultations";
import { IntakeIntroScreen } from "~/patients/screens/consultations/intake/IntakeIntroScreen";
import { CompletedCheckins, Scripts, ToDo } from "../components";
import { Medications } from "../components/myMedication/Medications";
import { PatientMedicationDetails } from "../components/myMedication/PatientMedicationDetails";
import { usePatientIsAuth } from "../hooks/queries/usePatientIsAuth";
import { ConsultationHomeLayout } from "../layout/ConsultationHomeLayout";
import { ConfirmPayment } from "../screens/ConfirmPayment";
import { CheckListScreenIntake } from "../screens/consultations/intake/CheckListScreenIntake";
import { StartIntake } from "../screens/consultations/intake/StartIntake";
import { Payment } from "../screens/Payment";
import { SchedulingInstructions } from "../screens/SchedulingInstructions";
import { ModalRouter } from "./ModalRouter";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  const { data: isLoggedIn, isLoading } = usePatientIsAuth();

  if (isLoading) return <LoadingState className="h-screen-calculated" />;
  return (
    <>
      <Routes location={previousLocation ?? location}>
        <Route element={<VerifiedEmail />} path={ROUTES.verifiedEmail} />
        {/* PUBLIC ONLY ROUTES */}
        {!isLoggedIn && (
          <>
            <Route
              element={<Navigate to={ROUTES.login} replace />}
              path={"*"}
            />
            <Route path={ROUTES.notFound} element={<NotFoundScreen />} />
            <Route element={<SignUpActivePatient />} path={ROUTES.signUp} />
            <Route
              element={<SignUpNewPatient />}
              path={ROUTES.signUpNewPatient}
            />
            <Route element={<Login />} path={ROUTES.login} />
            <Route element={<ForgotPassword />} path={ROUTES.forgotPassword} />
            <Route element={<ResetPassword />} path={ROUTES.resetPassword} />
          </>
        )}
        {/* PRIVATE ONLY ROUTES */}
        {isLoggedIn && (
          <>
            <Route element={<Navigate to={ROUTES.home} />} path={"*"} />
            <Route element={<PatientCheckIn />} path={ROUTES.checkIn} />
            <Route element={<Screening />} path={ROUTES.screening} />
            <Route element={<Intake />} path={ROUTES.intake} />
            <Route element={<Payment />} path={ROUTES.payment} />
            <Route element={<ConfirmPayment />} path={ROUTES.confirmPayment} />
            <Route
              element={<ScreeningAlreadyCompleted />}
              path={ROUTES.screeningAlreadyCompleted}
            />
            <Route
              element={<ConsultationPartCompleted />}
              path={ROUTES.consultationTypeCompleted}
            />
            <Route element={<StartScreening />} path={ROUTES.startScreening} />
            <Route path={ROUTES.notFound || "*"} element={<NotFoundScreen />} />
            <Route
              path={ROUTES.internalError}
              element={<InternalErrorScreen />}
            />
            <Route path={ROUTES.verifyEmail} element={<VerifyEmail />} />
            <Route
              path={ROUTES.completeScreeningConsultation}
              element={<CheckListScreenScreening />}
            />
            <Route
              path={ROUTES.completeIntakeConsultation}
              element={<CheckListScreenIntake />}
            />
            <Route
              path={ROUTES.leadScreeningIntroScreen}
              element={<ScreeningIntroScreen />}
            />
            <Route
              path={ROUTES.leadIntakeIntroScreen}
              element={<IntakeIntroScreen />}
            />
            <Route path={ROUTES.startIntake} element={<StartIntake />} />

            <Route
              path={ROUTES.schedulingInstructions}
              element={<SchedulingInstructions />}
            />

            <Route element={<Layout />}>
              <Route element={<ConsultationHomeLayout />}>
                <Route
                  element={<LeadPatientHomeScreen />}
                  path={ROUTES.leadPatientHome}
                />
              </Route>
              <Route element={<Settings />} path={ROUTES.settings} />
              <Route element={<MedicationLayout />}>
                <Route element={<Medications />} path={ROUTES.medications} />
                <Route element={<Scripts />} path={ROUTES.requests} />
              </Route>
              <Route
                element={<PatientMedicationDetails />}
                path={ROUTES.medicationDetails}
              />
              <Route element={<HomeLayout />}>
                <Route element={<ToDo />} path={ROUTES.home} />
                <Route
                  element={<CompletedCheckins />}
                  path={ROUTES.completedCheckins}
                />
              </Route>
            </Route>
          </>
        )}
      </Routes>
      <Routes>
        <Route
          path="*"
          element={<ModalRouter showModal={!!previousLocation} />}
        />
      </Routes>
    </>
  );
};
