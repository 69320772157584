import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";

export const ErrorText = ({
  children,
  className,
}: PropsWithChildren<Styled>) => (
  <p
    className={twMerge("text-xs text-error first-letter:uppercase", className)}
  >
    {children}
  </p>
);
