import { PATIENT_STATUS } from "~/common/constants";
import { GroupOptions } from "~/common/utils";

export const ENGAGEMENT_TYPES = {
  active: "active",
  inactive: "inactive",
} as const;

export const ENGAGEMENT_OPTIONS = Object.values(ENGAGEMENT_TYPES);

export const PATIENT_STATUS_BASIC_OPTIONS = Object.values(PATIENT_STATUS);

export const allFilters = [
  ...ENGAGEMENT_OPTIONS,
  ...PATIENT_STATUS_BASIC_OPTIONS,
];

export type EngagementTypes = keyof typeof ENGAGEMENT_TYPES;

export const engagementFilterOptions: GroupOptions[] = Object.values(
  ENGAGEMENT_TYPES,
).map((option) => ({
  value: option,
  label: option,
}));
