import { twMerge } from "tailwind-merge";

import {
  ImagePromptCard,
  LoadingState,
  WaveBackgroundLayout,
} from "~/common/components";
import SuccessIcon from "~/common/components/layouts/SuccessIcon";

interface ConsultationCompletedProps {
  title: string;
  subtitle: string;
  isFinal: boolean;
  isLoading: boolean;
  isPending: boolean;
  handleComplete: () => void;
  handleGoBackFinalScreen: () => void;
  providerPictureSrc?: string;
}
export const ConsultationCompleted = ({
  handleGoBackFinalScreen,
  title,
  subtitle,
  isFinal,
  providerPictureSrc,
  isLoading,
  isPending,
  handleComplete,
}: ConsultationCompletedProps) => {
  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  return (
    <WaveBackgroundLayout className="relative justify-center p-6">
      <ImagePromptCard
        title={title}
        subtitle={subtitle}
        handleContinue={handleComplete}
        handleGoBack={handleGoBackFinalScreen}
        pendingContinue={isPending}
        continueLabel={isFinal ? "Submit request" : "Continue"}
        imageContainerClassName={twMerge(
          "bg-green-100",
          isFinal && providerPictureSrc && "bg-primary-30",
        )}
      >
        {isFinal && providerPictureSrc ? (
          <img
            className="rounded-full"
            src={providerPictureSrc}
            alt="ProfilePicture"
          />
        ) : (
          <SuccessIcon className="w-full" />
        )}
      </ImagePromptCard>
    </WaveBackgroundLayout>
  );
};
