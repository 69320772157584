import { twMerge } from "tailwind-merge";

import { Button } from "./button";

export const cancelSubmitVariants = [
  "full-outline-black",
  "right-outline-black",
  "separated-outline-none",
] as const;

export type CancelSubmitButtonsVariant = (typeof cancelSubmitVariants)[number];

interface CancelSubmitButtonsProps {
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  cancelClassName?: string;
  submitClassName?: string;
  disableSubmit?: boolean;
  disableCancel?: boolean;
  isSubmitting?: boolean;
  variant?: CancelSubmitButtonsVariant;
  containerClassName?: string;
  submitButtonType?: HTMLButtonElement["type"];
}

export const CancelSubmitButtons = ({
  submitButtonLabel,
  cancelButtonLabel,
  handleCancel,
  handleSubmit,
  cancelClassName,
  submitClassName,
  disableSubmit,
  disableCancel,
  isSubmitting = false,
  containerClassName,
  variant = "full-outline-black",
  submitButtonType = "submit",
}: CancelSubmitButtonsProps) => {
  return (
    <div
      className={twMerge(
        "flex w-full gap-2",
        variant === "right-outline-black" && "w-5/12 items-end",
        variant === "separated-outline-none" && "justify-between",
        containerClassName,
      )}
    >
      <Button
        onClick={handleCancel}
        variant={
          variant === "full-outline-black" || variant === "right-outline-black"
            ? "outline-black"
            : "outline-none"
        }
        className={twMerge(
          "w-fit whitespace-nowrap",
          variant === "full-outline-black" && "w-full",
          variant === "right-outline-black" && "w-full",
          cancelClassName,
        )}
        size="sm"
        disabled={disableCancel}
      >
        {cancelButtonLabel ?? "Cancel"}
      </Button>
      <Button
        onClick={handleSubmit}
        className={twMerge(
          "w-full whitespace-nowrap",
          variant === "separated-outline-none" && "w-1/3 md:w-2/5",
          variant === "right-outline-black" && "w-full",
          submitClassName,
        )}
        disabled={disableSubmit}
        type={submitButtonType}
        size="sm"
        isLoading={isSubmitting}
      >
        {submitButtonLabel ?? "Submit"}
      </Button>
    </div>
  );
};
