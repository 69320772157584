import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { TABLE_DATA_VARIANTS, TableDataVariantsType } from "~/common/constants";

interface TableDataProps
  extends PropsWithChildren,
    React.TdHTMLAttributes<HTMLTableCellElement> {
  className?: string;
  variant?: TableDataVariantsType;
}

export const TableData = ({
  children,
  className,
  variant,
  ...props
}: TableDataProps) => (
  <td
    className={twMerge(
      "border-t border-gray-300 px-4 py-3",
      variant === TABLE_DATA_VARIANTS.outlined &&
        "border-none p-0 font-light text-gray-35",
      className,
    )}
    {...props}
  >
    {children}
  </td>
);
