import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";

import { Button, Typography } from "~/common/components";
import BackArrow from "~/common/components/icons/BackArrow";
import { useConfirmStripePayment } from "~/patients/hooks/queries/payments/useCreatePayment";
import { useGetCurrentPatient } from "~/patients/hooks/queries/useGetCurrentPatient";
import { ROUTES } from "~/patients/router";

export const CheckoutForm = ({ amount }: { amount?: number }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { data: user } = useGetCurrentPatient();
  const { preferred_reference: providerName } = { ...user?.provider };

  const { createPaymentMutation, isPending } = useConfirmStripePayment();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createPaymentMutation();
  };

  return (
    <form
      className="flex h-screen-calculated flex-col justify-between bg-primary-50 p-8 md:px-64"
      id="payment-form"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-6">
        <Link
          to={ROUTES.leadPatientHome}
          className="flex flex-row items-center gap-1"
        >
          <BackArrow className="size-5" />
          Home
        </Link>
        <Typography className="font-outfit text-3xl font-semibold">
          Consultation with {providerName}
        </Typography>
        <Typography className="text-base font-semibold">
          Please complete the payment to start the consultation.
        </Typography>
        <Typography className="text-base">
          This covers the intake questionnaire and the live appointment with{" "}
          {providerName}. The intake allows us to gather crucial information
          about your health, enabling {providerName} to provide a more
          personalized and effective consultation.
        </Typography>
        <Typography className="text-lg text-primary">
          Payment details <br /> <span className="font-medium">${amount}</span>
        </Typography>
        <PaymentElement id="payment-element" />
      </div>
      <Button
        isLoading={isPending}
        disabled={!stripe || !elements}
        type="submit"
      >
        Confirm
      </Button>
    </form>
  );
};
