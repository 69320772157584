import { PATIENT_STATUS } from "~/common/constants";

export const BASE_MEDICATION_PATH = "/medications";
export const BASE_LEAD_PATH = "/lead";

export const ROUTES = {
  leadPatientHome: `${BASE_LEAD_PATH}`,
  leadScreeningIntroScreen: `${BASE_LEAD_PATH}/screening/intro-screen`,
  leadIntakeIntroScreen: `${BASE_LEAD_PATH}/intake/intro-screen`,
  home: `/`,
  completedCheckins: `/completed-checkins`,
  consultationTypeCompleted: "/:consultationType/completed",
  settings: "/settings",
  medications: `${BASE_MEDICATION_PATH}/medication-history`,
  requests: `${BASE_MEDICATION_PATH}/requests`,
  medicationDetails: "/medications/:id",
  login: "/login",
  signUp: "/signup",
  verifyEmail: "/verify-email",
  verifiedEmail: "/email-verified/:patientId",
  signUpNewPatient: "/signup/lead",
  resetPassword: "/password-reset",
  forgotPassword: "/forgot-password",
  checkIn: "/checkIn/:id",
  screening: `${BASE_LEAD_PATH}/screening/:id`,
  screeningAlreadyCompleted: `${BASE_LEAD_PATH}/screening/completed`,
  startScreening: `${BASE_LEAD_PATH}/screening/:id/start`,
  payment: `${BASE_LEAD_PATH}/payment`,
  confirmPayment: "/lead/confirm-payment",
  notFound: "/not-found",
  internalError: "/internal-error",
  intake: `${BASE_LEAD_PATH}/intake/:id`,
  startIntake: `${BASE_LEAD_PATH}/intake/:id/start`,
  completeScreeningConsultation: "/consultation/screening",
  completeIntakeConsultation: "/consultation/intake",
  schedulingInstructions: `${BASE_LEAD_PATH}/scheduling-instructions`,
} as const;

export const MODAL_ROUTES = {
  successModal: "/successModal",
} as const;

export const homePathByStatus = {
  [PATIENT_STATUS.ongoing_care]: ROUTES.home,
  [PATIENT_STATUS.screening_started]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.screening_completed]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.screening_submitted]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.referred_after_screening]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.intake_started]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.intake_submitted]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.appointment_scheduled]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.consultation_completed]: ROUTES.leadPatientHome,
  [PATIENT_STATUS.consultation_requested]: ROUTES.leadPatientHome,
} as const;
