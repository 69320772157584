import { SVGProps } from "react";

import {
  DIRECTIONS,
  PATIENT_STATUS,
  TEAM_MEMBER_PERMISSIONS,
  TEAM_MEMBER_VALUES,
} from "~/common/constants";
import { UserTypes } from "~/common/constants/types";
import { CommentTitleType, PatientInformation } from "~/patients/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";

export interface AuthenticationResponse {
  access_token: string;
}

export interface PasswordRequirement {
  condition: string;
  valid: (password: string) => boolean;
}
export type IconProps = SVGProps<SVGSVGElement>;

export interface DateObject {
  day: string;
  month: string;
}

export interface UpdateNoteData {
  content: string | null;
}

export interface BasicUserData {
  name: string;
  last_name: string;
  type: UserTypes;
  email: string;
  id?: string;
}

export interface Patient extends BasicUserData {
  id: string;
  last_feed?: PatientFeed;
  status: PatientStatus;
  archived_at?: string;
  is_reviewed: boolean;
}

export interface PatientBasicData extends BasicUserData {
  id: string;
  status: PatientStatus;
  archived_at?: string;
}

export type Directions = (typeof DIRECTIONS)[keyof typeof DIRECTIONS];

export type TeamMemberType =
  (typeof TEAM_MEMBER_VALUES)[keyof typeof TEAM_MEMBER_VALUES];

export type PermissionsCodes =
  (typeof TEAM_MEMBER_PERMISSIONS)[keyof typeof TEAM_MEMBER_PERMISSIONS];

export interface Permission extends InterfaceWithId {
  code: PermissionsCodes;
  description?: string;
}

export interface UserPermission extends InterfaceWithId {
  user_id: string;
  permission_id: string;
  permission: Permission;
  deleted_at?: string;
}

export interface TeamMember extends InterfaceWithId {
  email: string;
  name: string;
  last_name: string;
  phone_number: string;
  type: TeamMemberType;
  user_permissions: UserPermission[];
  preferred_reference?: string;
  profile_picture_path?: string;
  signature_name?: string;
  acuity_client_id?: string;
}

export interface ProviderBasicData extends InterfaceWithId {
  signature_name: string;
  preferred_reference: string;
  email: string;
}

export interface InterfaceWithId {
  id: string;
}

export interface Comment {
  id: string;
  title: CommentTitleType;
  text: string;
  user: PatientInformation;
  created_at: string;
}

export interface Option {
  label: string;
  value: string;
  description?: string;
  type?: string;
  disabled?: boolean;
}

export interface GroupOptions {
  label: string;
  value: string;
}

export interface Group {
  group?: string;
  groupOptions: GroupOptions[];
}

export type PatientStatus =
  (typeof PATIENT_STATUS)[keyof typeof PATIENT_STATUS];

export const CONSULTATION_TYPES = {
  intake: "intake",
  screening: "screening",
} as const;

export type ConsultationTypes = keyof typeof CONSULTATION_TYPES;
