import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { ConsultationCompleted } from "~/patients/components/consultations/shared";
import {
  useCompleteScreening,
  useGetCurrentPatient,
  useGetCurrentScreeningBundle,
} from "~/patients/hooks";

interface ScreeningCompletedScreenProps {
  handleGoBackFinalScreen: () => void;
}
export const ScreeningCompletedScreen = ({
  handleGoBackFinalScreen,
}: ScreeningCompletedScreenProps) => {
  const { data: user } = useGetCurrentPatient();

  const { profile_picture_path: providerPictureSrc } = { ...user?.provider };
  const { id: patientScreeningId } = useParams();
  const { completeScreeningMutation, isPending } = useCompleteScreening();
  const { data, isLoading, isFetching } = useGetCurrentScreeningBundle();

  if (!data) return;

  const { sections_completed, total_sections } = data;
  const sectionCompleted = (sections_completed ?? 0) + 1;
  const isFinalScreening = sectionCompleted === total_sections;

  const title = `You’ve completed Section ${sectionCompleted}!`;

  const subtitle = `You can’t edit your answers once you submit your request.`;

  const handleCompleteScreening = () =>
    patientScreeningId && completeScreeningMutation(patientScreeningId);

  if (isLoading || isFetching) return <LoadingState />;

  return (
    <ConsultationCompleted
      handleGoBackFinalScreen={handleGoBackFinalScreen}
      title={title}
      subtitle={subtitle}
      isFinal={isFinalScreening}
      providerPictureSrc={providerPictureSrc}
      isLoading={isLoading}
      isPending={isPending}
      handleComplete={handleCompleteScreening}
    />
  );
};
