import { ReactElement } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { ErrorText } from "~/common/components";
import { Directions, Option } from "~/common/utils";
import ListBoxContent from "./ListBoxContent";

export interface SelectProps<TFieldValues extends FieldValues> {
  id: FieldPath<TFieldValues>;
  isDisabled?: boolean;
  label?: string;
  labelClassName?: string;
  noPadding?: boolean;
  options: Option[];
  optionsClassName?: string;
  placeholder?: string;
  value?: string;
  checkSelectedOption?: boolean;
  buttonClassName?: string;
  containerClassName?: string;
  control?: Control<TFieldValues>;
  direction?: Directions;
  error?: string;
  icon?: ReactElement;
  onChange?: (value: string | number) => void;
  onClick?: (event?: React.MouseEvent) => void;
  optionsOnClick?: (event?: React.MouseEvent) => void;
  buttonContent?: () => React.JSX.Element;
}

const Select = <TFieldValues extends FieldValues>({
  buttonClassName,
  containerClassName,
  control,
  direction,
  error,
  icon,
  id,
  isDisabled = false,
  label,
  labelClassName,
  noPadding = false,
  onChange,
  options,
  optionsClassName,
  placeholder,
  value,
  checkSelectedOption,
  onClick,
  optionsOnClick,
  buttonContent,
}: SelectProps<TFieldValues>) => (
  <div
    className={twMerge(
      "flex w-full flex-col gap-2 font-light",
      containerClassName,
    )}
  >
    {label && (
      <label
        className={twMerge("text-sm font-medium text-gray-700", labelClassName)}
        htmlFor={id}
      >
        {label}
      </label>
    )}
    {!!control && (
      <Controller
        control={control}
        name={id}
        render={({
          field: { onChange: controllerOnChange, value: formValue },
        }) => (
          <ListBoxContent
            value={`${formValue}`}
            hasError={!!error}
            isDisabled={isDisabled}
            onChange={onChange}
            controllerOnChange={controllerOnChange}
            options={options}
            optionsClassName={optionsClassName}
            placeholder={placeholder}
            buttonClassName={twMerge(
              "font-light",
              buttonClassName,
              error && "bg-red-50 text-error border-error",
            )}
            direction={direction}
            checkSelectedOption={checkSelectedOption}
            onClick={onClick}
            optionsOnClick={optionsOnClick}
            buttonContent={buttonContent}
          />
        )}
      />
    )}

    {!control && (
      <div className="flex h-full items-center gap-2">
        <ListBoxContent
          noPadding={!!noPadding}
          icon={icon}
          value={value ?? ""}
          hasError={!!error}
          isDisabled={isDisabled}
          onChange={onChange}
          options={options}
          optionsClassName={optionsClassName}
          placeholder={placeholder}
          buttonClassName={twMerge("font-light", buttonClassName)}
          direction={direction}
          labelClassName={labelClassName}
          checkSelectedOption={checkSelectedOption}
          onClick={onClick}
          optionsOnClick={optionsOnClick}
          buttonContent={buttonContent}
        />
      </div>
    )}

    {error && <ErrorText>{error}</ErrorText>}
  </div>
);

export default Select;
