import { useMutation, useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { CONSULTATION_TYPES } from "~/common/utils";
import { CompletePatientScreeningResponse } from "~/common/utils/consultations/screening";
import { completeScreening } from "~/patients/api";
import { ROUTES } from "~/patients/router";

export const useCompleteScreening = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: completeScreeningMutation, isPending } = useMutation({
    mutationFn: (patient_screening_id: string) =>
      completeScreening(patient_screening_id),
    onSuccess: async (data: CompletePatientScreeningResponse | null) => {
      const path = data
        ? generatePath(ROUTES.startScreening, {
            id: data.id,
          })
        : generatePath(ROUTES.consultationTypeCompleted, {
            consultationType: CONSULTATION_TYPES.screening,
          });
      await queryClient.invalidateQueries({ queryKey: ["getCurrentPatient"] });
      navigate(path);
    },
    onError: () =>
      toast.error(
        "An error occurred while submitting this section. If the issue persists, please refresh the page.",
      ),
  });

  return { completeScreeningMutation, isPending };
};
